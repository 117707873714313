<template>
  <div class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-py-6" style="min-height: 500px;">
    <Table
      :name="name"
      :total="total"
      :fields="fields"
      :items="receivedRequest"
      :showTotal="false"
      :hasFilter="false"
      :hasSearch="true"
      :perPage="10"
      :isBusy="isBusy"
      @changedPage="setPage"
      @setSearch="setSearch"
    >
      <template #actionsCustom="row">
        <b-button @click.prevent="response(row.row)" size="sm" variant="primary" :disabled="row.row.item.status === 'Rejected'">
          <span>{{ $t('global.response') }}</span>
        </b-button>
      </template>

      <template #contactInfo="row">
        <div class="fd-w-full fd-flex fd-items-center">
          <span class="fd-block fd-mr-2">{{ getName(row.row.item.contact_info) }}</span>

          <SvgLoader :id="row.row.item.id.toString()" :name="'phone2'" class="fd-mr-2 fd-cursor-pointer" />
          <SvgLoader :id="row.row.item.id.toString() + '-email'" :name="'sms2'" class="fd-cursor-pointer" />

          <b-tooltip :target="row.row.item.id.toString()" placement="bottom" boundary="document">
            <span class="fd-text-xs fd-font-normal">{{ row.row.item.contact_info.phone }}</span>
          </b-tooltip>
          <b-tooltip :target="row.row.item.id.toString() + '-email'" placement="bottom" boundary="document">
            <span class="fd-text-xs fd-font-normal">{{ row.row.item.contact_info.email }}</span>
          </b-tooltip>
        </div>
      </template>
      <template #invitedAt="row">
        <div class="fd-w-full fd-flex fd-items-center">
          <span class="fd-block fd-mr-3">{{ row.row.item.invited_at.split('T')[0] }}</span>
          <span class="fd-block">{{ row.row.item.invited_at.split('T')[1] }}</span>
        </div>
      </template>
    </Table>

    <div>
      <b-sidebar
        id="request-details-sidebar-id"
        no-header
        bg-variant="white"
        shadow right lazy backdrop
        :backdrop-variant="'dark'"
      >
        <template #default="{ hide }">
          <div class="fd-flex fd-items-center fd-justify-between fd-p-3" style="background-color: #E9EDEB;">
            <span class="fd-text-theme-2 fd-text-base">{{ $t('global.details') }}</span>
            <div @click="toggleEditSidebar" class="fd-cursor-pointer">
              <SvgLoader :name="'close'" />
            </div>

          </div>
          <div v-if="requestDetails" class="fd-p-3">
            <div class="fd-p-2 fd-mb-4" style="background-color: #FBFBFB">
              <div class="project-image fd-mx-auto fd-overflow-hidden">
                <img :src="requestDetails.thumbnail" alt="" class="object-cover" />
              </div>
              <div class="fd-mt-6">
                <div>
                  <label for="name" class="fd-text-xs fd-text-theme-9">{{ $t('global.name') }}</label>
                  <b-form-input id="name" v-model="requestDetails.name" type="text" disabled />
                </div>
                <div>
                  <label for="business" class="fd-mt-3 fd-text-xs fd-text-theme-9">{{ $t('connectedPage.businessId') }}</label>
                  <b-form-input id="business" v-model="requestDetails.business_id" type="text" disabled />
                </div>
                <div class="fd-mt-3 fd-text-theme-9">
                  <label for="address" class="fd-text-xs">{{ $t('connectedPage.businessAddress') }}</label>
                  <b-form-textarea id="address" v-model="requestDetails.business_address" rows="2" max-rows="3" disabled />
                </div>
                <div class="fd-mt-3">
                  <label for="phone" class="fd-text-xs fd-text-theme-9">{{ $t('global.phone') }}</label>
                  <b-form-input id="phone" v-model="requestDetails.phone" type="text" disabled />
                </div>
                <div class="fd-mt-3">
                  <label for="website" class="fd-text-xs fd-text-theme-9">{{ $t('global.website') }}</label>
                  <b-form-input id="website" v-model="requestDetails.website" type="text" disabled />
                </div>
                <div class="fd-mt-3">
                  <label for="email" class="fd-text-xs fd-text-theme-9">{{ $t('global.email') }}</label>
                  <b-form-input id="email" v-model="requestDetails.email" type="text" disabled />
                </div>
              </div>
            </div>
            <div class="fd-p-2" style="background-color: #FBFBFB">
              <div class="fd-mt-3 fd-text-theme-9">
                <label for="requestMessage" class="fd-text-xs">{{ $t('connectedPage.requestMessage') }}</label>
                <b-form-textarea id="requestMessage" v-model="requestDetails.request_message" rows="5" max-rows="6" disabled />
              </div>
              <div class="fd-mt-3">
                <label for="commissionFee" class="fd-text-xs fd-text-theme-9">{{ $t('connectedPage.commissionFee') }}</label>
                <b-form-input id="commissionFee" v-model="form.commission_rate" type="text" />
              </div>
            </div>

            <div class="fd-flex fd-items-center fd-justify-center fd-mt-5">
              <b-button @click="accept" block variant="primary" class="fd-mr-2">{{ $t('global.accept') }}</b-button>
              <b-button @click="decline" block variant="outline-danger" style="margin-top: 0 !important;">{{ $t('global.decline') }}</b-button>
            </div>
          </div>
        </template>

      </b-sidebar>
    </div>
  </div>
</template>

<script>
import Table from "@/views/components/global/Table";
import Template from "@/views/pages/projectManagement/projects/Template";
import { BButton, BTooltip, BSidebar, BFormInput, BFormTextarea } from 'bootstrap-vue'
import {mapGetters} from "vuex";

export default {
  name: "ReceivedRequest",
  components: {Template, Table, BButton, BTooltip, BSidebar, BFormInput, BFormTextarea},
  data() {
    return {
      loading: false,
      search: '',
      form: {
        commission_rate: ''
      }
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    this.getData()
  },
  watch: {
    search: {
      handler(newVal) {
        let data = {
          id: this.$route.params.id,
        }
        if (newVal) {
          data.search = newVal
        }
        this.getData(data)
      }
    }
  },
  computed: {
    ...mapGetters({
      requestDetails: 'rs/requestDetails',
      receivedRequest: 'rs/receivedRequest',
      total: 'rs/receivedRequestTotal'
    }),
    fields() {
      return [
        { key: 'name', label: this.$t('table.rsName'), sortable: true, sortDirection: 'desc', thStyle: { width: "20%" } },
        { key: 'business_id', label: this.$t('table.businessId'), thStyle: { width: "15%" } },
        { key: 'contact_info', label: this.$t('table.contactInfo'), thStyle: { width: "25%" } },
        {
          key: 'invited_at',
          label: this.$t('table.requestedAt'),
          thStyle: { width: "15%" }
          },
        { key: 'status', label: this.$t('table.status'), thStyle: { width: "15%" } },
        { key: 'actions', label: this.$t('table.actions'), class: 'text-right', thStyle: { width: "13%" } }
      ]
    },
    name() {
      return this.$t('navbar.receivedRequest')
    },
    isBusy() {
      return this.loading
    }
  },
  methods: {
    getData(data = {}) {
      this.loading = true

      this.$store.dispatch('rs/getReceivedRequest', data).finally(() => {
        this.loading = false
      })
    },
    setFilters(filters) {
      console.log(filters)
    },
    setPage(number) {
      let data = {
        id: this.$route.params.id,
        number
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    setSearch(search) {
      this.search = search
    },
    toggleEditSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'request-details-sidebar-id')
    },
    response(row) {
      this.toggleEditSidebar()
      this.$store.dispatch('rs/getRequestDetails', row.item.id)
    },
    getName(contact) {
      return contact.first_name + ' ' + contact.last_name
    },
    accept() {
      this.$store.dispatch('rs/updateRequest', {
        id: this.requestDetails.id,
        data: {
          status: 'accept',
          commission_rate: this.form.commission_rate
        }
      })
        .then(() => {
          this.toggleEditSidebar()
          this.form.commission_rate = ''
          this.getData()
        })
    },
    decline() {
      this.$store.dispatch('rs/updateRequest', {
        id: this.requestDetails.id,
        data: {
          status: 'reject'
        }
      })
        .then(() => {
          this.toggleEditSidebar()
          this.form.commission_rate = ''
          this.getData()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.project-image {
  width: 229px;
  height: 173px;
  border-radius: 3px;
  img {
    filter: drop-shadow(0px 4px 20px rgba(207, 207, 207, 0.25));
    width: 229px;
    height: 173px;
  }
}
</style>